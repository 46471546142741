export const Endpoints = {
  payment: {
    appEngines: {
      root: 'https://payment-rest-6lhucily4q-uc.a.run.app',
      createPlan() {
        return `${this.root}/plan-rest/create`;
      },
      updatePlan(planId: string) {
        return `${this.root}/plan-rest/update/${planId}`;
      },
      setupIntent() {
        return `${this.root}/payment-rest/setup-intent/create`;
      },
      createPrice(planId: string) {
        return `${this.root}/plan-rest/${planId}/price/create`;
      },
      setPriceDefault(planId: string) {
        return `${this.root}/plan-rest/${planId}/price/default`;
      },
      createSubscription() {
        return `${this.root}/subscription-rest/create`;
      },
      upgradeSubscription() {
        return `${this.root}/subscription-rest/upgrade`;
      },
      pauseSubscription() {
        return `${this.root}/subscription-rest/pause`;
      },
      resumeSubscription() {
        return `${this.root}/subscription-rest/resume`;
      },
      cancelSubscription() {
        return `${this.root}/subscription-rest/cancel`;
      },
      stopSubscription() {
        return `${this.root}/subscription-rest/stop`;
      },
      attachPaymentMethod() {
        return `${this.root}/payment-rest/payment-method/attach`;
      },
      detachPaymentMethod() {
        return `${this.root}/payment-rest/payment-method/detach`;
      },
      changePaymentMethod() {
        return `${this.root}/subscription-rest/update`;
      },
    },
  },

  collection: {
    appEngines: {
      root: 'https://collection-rest-6lhucily4q-uc.a.run.app',
      private(collectionId: string) {
        return `${this.root}/collection-rest/collection-path/${collectionId}/shared`;
      },
      shared(collectionId: string) {
        return `${this.root}/collection-rest/collection-path/${collectionId}/unshared`;
      },
    },
  },

  authProviderConfig: {
    appEngines: {
      root: 'https://auth-provider-config-rest-6lhucily4q-uc.a.run.app',
      get(providerId: string) {
        return `${this.root}/auth-provider-config-rest/rest/auth-provider-configs/rest/auth-provider-config-get/${providerId}`;
      },
      create(workspaceId: string) {
        return `${this.root}/auth-provider-config-rest/rest/auth-provider-configs/rest/auth-provider-config-create/${workspaceId}`;
      },
      update(providerId: string) {
        return `${this.root}/auth-provider-config-rest/rest/auth-provider-configs/rest/auth-provider-config-update/${providerId}`;
      },
      delete(providerId: string) {
        return `${this.root}/auth-provider-config-rest/rest/auth-provider-configs/rest/auth-provider-config-delete/${providerId}`;
      },
    },
  },

  user: {
    appEngines: {
      root: 'https://user-rest-6lhucily4q-uc.a.run.app',
      createUser() {
        return `${this.root}/user-rest/user-create`;
      },

      getById(userId: string) {
        return `${this.root}/user-rest/get_by_id/${userId}`;
      },

      checkFileExist(filePath: string) {
        return `${this.root}/user-rest/check-file/${filePath}`;
      },
    },
  },

  invitation: {
    appEngines: {},
  },

  workspace: {
    appEngines: {},
  },

  workspace_member: {
    appEngines: {},
  },

  subscription: {
    appEngines: {},
  },

  notification: {
    appEngines: {
      root: 'https://notification-rest-6lhucily4q-uc.a.run.app',
      resendVerificationLink() {
        return `${this.root}/notification-rest/rest/notifications/event/user-created`;
      },
      getFMCToken() {
        return `${this.root}/notification-rest/notifications/token/get`;
      },
      createFMCToken() {
        return `${this.root}/notification-rest/notifications/token/create`;
      },
      addFMCToken() {
        return `${this.root}/notification-rest/notifications/token/add`;
      },
      removeFMCToken(userId: string) {
        return `${this.root}/notification-rest/notifications/token/remove/${userId}`;
      },
    },
  },

  default: {
    appEngines: {
      root: 'https://async-video.uc.r.appspot.com',
      workspaceStatistics() {
        return `${this.root}/rests/defaults/get-statistics`;
      },
      videoStatistics() {
        return `${this.root}/rests/defaults/get-video-statistics`;
      },
    },
  },
};
