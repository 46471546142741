import { useState } from 'react';

import { useAuth } from '@/shared/hooks/useAuth';

type ICreate = {
  [key: string]: any;
};

type IUpdate = {
  [key: string]: any;
};

export type IError = {
  statusCode: number;
  message: string;
};

// State
export type IApiState<T> = {
  isSuccess: boolean;
  isLoading: boolean;
  error: IError | null;
  data: T;
};

// Args
type IPostArgs<C> = { apiUrl: string; body?: C };
type IGetAllArgs = { apiUrl: string };
type IGetArgs = { apiUrl: string };
type IPutArgs<U> = { apiUrl: string; body: U };
type IRemoveArgs = { apiUrl: string };

// Verbs
type IPostVerb<C> = (args: IPostArgs<C>) => Promise<void>;
type IGetAllVerb = (args: IGetAllArgs) => Promise<void>;
type IGetVerb = (args: IGetArgs) => Promise<void>;
type IPutVerb<U> = (args: IPutArgs<U>) => Promise<void>;
type IRemoveVerb = (args: IRemoveArgs) => Promise<void>;

// Returns
type IGetAllAPI<T> = {
  state: IApiState<T[]>;
  getAll: IGetAllVerb;
};

type IGetAPI<T> = {
  state: IApiState<T | null>;
  get: IGetVerb;
};

type IPostAPI<T, C> = {
  state: IApiState<T | null>;
  post: IPostVerb<C>;
};

type IPutAPI<T, U> = {
  state: IApiState<T | null>;
  put: IPutVerb<U>;
};

type IRemoveAPI<T> = {
  state: IApiState<T | null>;
  remove: IRemoveVerb;
};

export const useGetAllAPI = <T>(): IGetAllAPI<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const [data, setData] = useState<T[]>([]);
  const { user: authUser } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser?.customClaims.token}`,
  };

  const getAll = async ({ apiUrl }: IGetAllArgs): Promise<void> => {
    setIsLoading(true);
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers,
    });

    if (response.ok) {
      const result = await response.json();
      setIsLoading((_) => {
        setData((__) => {
          setIsSuccess((___) => {
            setError(null);
            return true;
          });
          return (result as T[]) ?? [];
        });
        return false;
      });
    } else {
      setIsLoading((_) => {
        setData((__) => {
          setIsSuccess((___) => {
            setError({
              message: response.statusText,
              statusCode: response.status,
            });
            return false;
          });
          return [];
        });
        return false;
      });
    }
  };

  return { state: { isSuccess, isLoading, error, data }, getAll };
};

export const useGetAPI = <T>(): IGetAPI<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const [data, setData] = useState<T | null>(null);
  const { user: authUser } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser?.customClaims.token}`,
  };

  const get = async ({ apiUrl }: IGetArgs): Promise<void> => {
    setIsLoading(true);
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers,
    });

    if (response.ok) {
      setIsSuccess(true);
      const result = await response.json();
      setData(result as T);
      setIsLoading(false);
      setError(null);
    } else {
      setData(null);
      setError({
        message: response.statusText,
        statusCode: response.status,
      });
      setIsLoading(false);
    }
    setIsSuccess(false);
  };

  return { state: { isSuccess, isLoading, error, data }, get };
};

export const usePostAPI = <T = unknown, C = ICreate>(): IPostAPI<T, C> => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const [data, setData] = useState<T | null>(null);
  const { user: authUser } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser?.customClaims.token}`,
  };

  const post = async ({ apiUrl, body }: IPostArgs<C>): Promise<void> => {
    setIsLoading(true);
    const init: RequestInit = {
      headers,
      method: 'POST',
    };
    if (body) {
      init.body = JSON.stringify(body);
    }
    const response = await fetch(apiUrl, init);
    if (response.ok) {
      const result = await response.json();
      setIsLoading((_) => {
        setData((__) => {
          setIsSuccess((___) => {
            setError(null);
            return true;
          });
          return result as T;
        });
        return false;
      });
    } else {
      setIsLoading((_) => {
        setData((__) => {
          setIsSuccess((___) => {
            setError({
              message: response.statusText,
              statusCode: response.status,
            });
            return false;
          });
          return null;
        });
        return false;
      });
    }
  };

  return { state: { isLoading, error, isSuccess, data }, post };
};

export const usePutAPI = <T = unknown, U = IUpdate>(): IPutAPI<T, U> => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const [data, setData] = useState<T | null>(null);
  const { user: authUser } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser?.customClaims.token}`,
  };
  const put = async ({ body, apiUrl }: IPutArgs<U>): Promise<void> => {
    setIsLoading(true);
    const response = await fetch(apiUrl, {
      body: JSON.stringify(body),
      method: 'PUT',
      headers,
    });
    if (response.ok) {
      const result = await response.json();
      setIsLoading((_) => {
        setData((__) => {
          setIsSuccess((___) => {
            setError(null);
            return true;
          });
          return result as T;
        });
        return false;
      });
    } else {
      setIsLoading((_) => {
        setData((__) => {
          setIsSuccess((___) => {
            setError({
              message: response.statusText,
              statusCode: response.status,
            });
            return false;
          });
          return null;
        });
        return false;
      });
    }
  };

  return { state: { isSuccess, isLoading, error, data }, put };
};

export const useRemoveAPI = <T>(): IRemoveAPI<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const [data, setData] = useState<T | null>(null);
  const { user: authUser } = useAuth();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser?.customClaims.token}`,
  };

  const remove = async ({ apiUrl }: IRemoveArgs): Promise<void> => {
    setIsLoading(true);
    const response = await fetch(apiUrl, {
      method: 'DELETE',
      headers,
    });

    if (response.ok) {
      const result = await response.json();
      setIsLoading((_) => {
        setData((__) => {
          setIsSuccess((___) => {
            setError(null);
            return true;
          });
          return result as T;
        });
        return false;
      });
    } else {
      setIsLoading((_) => {
        setData((__) => {
          setIsSuccess((___) => {
            setError({
              message: response.statusText,
              statusCode: response.status,
            });
            return false;
          });
          return null;
        });
        return false;
      });
    }
  };

  return { state: { isSuccess, isLoading, error, data }, remove };
};
